import React from 'react'
import Layout from '../components/layout';
import Sidebar from "../components/sidebar";
import Content from "../components/content";
import Footer from "../components/footer";
import Breadcrumbs from "../components/breadcrumbs";

const IndexPage = ({ location }) => {
  const path = location.pathname ? location.pathname : '';

  return (
    <Layout>
      <main className="flex">
        <Sidebar path={path} />

        <div className="flex flex-col justify-between">
          <div className="">
            <Breadcrumbs />
            <Content />
          </div>
          
          <Footer />
        </div>
      </main>
    </Layout>
  )
}

export default IndexPage
