import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import Link from './link'
import Tiles from './tiles'
import Topics from './topics'

const Content = () => {
  const { productsList, servicesList, topicsList } = useStaticQuery(graphql`
      query TileQuery {
          productsList: datoCmsProductsList {
            title
            products {
              title
              description
              slug
            }
          }

          servicesList: datoCmsServicesList {
            title
            products {
              title
              description
              slug
            }
          }

          topicsList: datoCmsTopicsList {
            title
            topics {
              title
              slug
            }
          }
      }
  `);

  return (
    <section className="px-6 pt-4 pb-8">
      <div className="">
        <h1 className="text-t1 font-medium mb-6">Admin documentation</h1>
        <p className="mb-8">
          Learn how to configure and secure your org using the Okta Admin Console, stay updated on new features and enhancements 
          in Release Notes, and build custom solutions with <Link to="/" className="text-blue-500 font-medium">Okta developer docs.</Link>
        </p>

        { productsList &&  <Tiles {...productsList} /> }
        { servicesList &&  <Tiles {...servicesList} />}
        { topicsList && <Topics {...topicsList} />}

        <p>Need some help? Reach out to <Link to="/" className="link">Okta support</Link> with your commetns or questions.</p>
      </div>
    </section>
  )
}

export default Content
