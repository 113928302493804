import React from 'react'
import Link from './link'

const TopicItem = ({ title, slug }) => (
  <li className="mb-2">
    <Link to={`/${slug}`} className="link">{title}</Link>
  </li>
)

const Topics = ({ title, topics }) => (
  <div className="mb-16 last:mb-0">
    { title && <h3 className="text-t3 text-gray-800 font-medium mb-6">{title}</h3>}

    <ul style={{ columnCount: "3" }} className="flex flex-col sm:block">
      { topics.map((item, i) => <TopicItem key={`topic_item_${i}`} {...item} />) }
    </ul>
  </div>
)

export default Topics
